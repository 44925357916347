export default {
    state: {
        team: localStorage.getItem('big-team') === 'null' ? null : JSON.parse(localStorage.getItem('big-team')),
        player: localStorage.getItem('big-player') === 'null' ? null : JSON.parse(localStorage.getItem('big-player')),
        leagueFilter: null,
        teamFilter: null
    },
    mutations: {
        setTeam(state, value) {
            state.team = value
        },
        setPlayer(state, value) {
            state.player = value
        },
        setLeagueFilter(state, value) {
            state.leagueFilter = value
        },
        setTeamFilter(state, value) {
            state.teamFilter = value
        },
        setCourtFilter(state, value) {
            state.courtFilter = value
        }
    },
    actions: {
        setTeam({ commit }, team) {
            localStorage.setItem('big-team', JSON.stringify(team))
            commit('setTeam', team)
        },
        setPlayer({ commit }, player) {
            localStorage.setItem('big-player', JSON.stringify(player))
            commit('setPlayer', player)
        },
        setLeagueFilter({ commit }, leagueFilter) {
            commit('setLeagueFilter', leagueFilter)
        },
        setTeamFilter({ commit }, teamFilter) {
            commit('setTeamFilter', teamFilter)
        }
    },
    getters: {
        getTeam: state => state.team,
        getPlayer: state => state.player,
        getLeagueFilter: state => state.leagueFilter,
        getTeamFilter: state => state.teamFilter
    }
}
